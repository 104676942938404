@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap");
// @import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "./sass/partials/mixins";
//poppins is also a nice font
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  // font-size: 62.5%;
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Nunito", sans-serif !important;
  height: 100%;
  overflow-x: hidden;
}

#root {
  height: 100%;
  width: 100%;
}

a {
  text-decoration: none !important;
}

// customize bootstrap variables here

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

.sidebar_on {
  margin-left: 0 !important;
}

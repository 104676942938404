.App {
  /*text-align: center;*/
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.App .box {
  width: 80%;
  height: 200px;
  background-color: #fff;
  text-align: center;
  font-size: 1.2rem;
}

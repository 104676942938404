.login {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(216, 216, 216);

  .form {
    background-color: #fff;
    height: 30rem;
    border-radius: 10px;

    .message_cover {
      height: 35px;
      .message {
        height: 100%;
        h3 {
          font-size: 1.4rem;
        }
      }
    }

    .image_container {
      height: 6.25rem;
      img {
        width: 270px;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}
